import { Reducer, createContext, Dispatch, useContext } from "react";

import { Loco } from "entities/Loco";

export const LocoBtnContext = createContext<{
    state: Loco[],
    dispatch: (value: LocoBtnAction) => void
}>({
    state: Array(20).fill(0).map(()=> ({})),
    dispatch: (value: LocoBtnAction) => {}
});

type IActionType = 'setParam' | 'setLocoId';

export interface LocoBtnAction {
    type: IActionType,
    locoIndx: number,
    param?: keyof Loco,
    payload?: any,
};

export const locoBtnReducer: Reducer<Loco[], LocoBtnAction> = (state, action) => {
    switch (action.type) {

        case 'setParam': {
            if (action.locoIndx >= state.length) {
                return state;
            }

            return [
                ...state.map((loco, indx) => {
                    if (indx === action.locoIndx) {

                        if (action.param === 'positionPresent') {
                            return {
                                ...loco,
                                [action.param!]: Number(action.payload),
                            };
                        }

                        return {
                            ...loco,
                            [action.param!]: action.payload,
                        };
                    }

                    return loco;
                })
            ]
        }
        default: {
            throw Error('Хз че за актион');
        }
    }
}

export const useLocoBtnContext = () => useContext<{state: Loco[], dispatch: Dispatch<LocoBtnAction>}>(LocoBtnContext);
