import { FC, memo, useEffect, useState, } from 'react';
import './StationByName.css';
import { ArrowsByName } from 'features/ArrowsByName';
import { TrafficLightsByName } from 'features/TrafficLightsByName';
import { useGlobalContext } from 'shared/contex/contex';
import { subscribeStation } from '../model/services/subscribeStation';
import { Status } from 'shared/ui/Status';

interface StationByNameProps {
    className?: string,
    stationName: string,
}

/** Станция по имени */
export const StationByName: FC<StationByNameProps> = memo((props: StationByNameProps) => {
    const {
        className,
        stationName,
    } = props;
    const {state: {statusMqtt, client}} = useGlobalContext();
    const [status, setStatus] = useState(false);

    useEffect(() => {
        if (statusMqtt !== 'ready' || client === undefined) {
            return;
        }
        subscribeStation(client, stationName, (status) => {setStatus(status === 'online')})
    }, [client, statusMqtt, stationName]);


    return (
        <div className={`StationByName ${className? className: ''}`}>
            <div className='StationByNameTitle'>
                {`Станция: ${stationName}`}
                <Status active={status}/>
            </div>
            
            <ArrowsByName
                className='StationByNameArrows'
                stationName={stationName}
            />
            {/* <TrafficLightsByName
                className='StationByNameTrafficLight'
                stationName={stationName}
            /> */}
        </div>
    );
});
