import { ButtonHTMLAttributes, FC, ReactNode } from 'react';

import './Button.css';

type ButtonTheme = 'Normal' | 'Clicked' | 'Clear';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string,
    children?: ReactNode,
    theme?: ButtonTheme,
}

/** Кнопочка */
export const Button: FC<ButtonProps> = (props) => {
    const {
        className,
        children,
        theme = 'Normal',
        ...otherProps
    } = props;

    return (
        <button 
            className={`${className? className: ''} Button Button${theme}`}
            {...otherProps}
        >
            {children}
        </button>
    );
};
