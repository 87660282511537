import { BrowserRouter } from 'react-router-dom';
import { ConnectMqttProvider } from './providers/ConnectMqttProvider';
import { ContextProvider } from './providers/ContextProvider';
import { Router } from './route/router';
import './App.css';


function App() {
    return (
        <div className='App'>
        <BrowserRouter>
            <ContextProvider>
                <ConnectMqttProvider>
                    <Router/>
                </ConnectMqttProvider>
            </ContextProvider>
        </BrowserRouter>
        </div>
    );
}

export default App;
