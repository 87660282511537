import { FC, memo } from 'react';

import './MainPage.css';
import { AppLink } from 'shared/ui/AppLink/ui/AppLink';
import { AppRoutes } from 'shared/route/route';
import { Button } from 'shared/ui/Button';

/** Главная страница */
export const MainPage: FC = memo(() => {

    return (
        <div className='MainPage'>
            Hello World!!!
            <div className='MainPageLocoPanel'>
                {Array(20).fill(0).map((_, indx) => {

                    const index = indx+1 < 10? `0${indx+1}`: indx+1;
                    return (
                        <AppLink
                            route={AppRoutes.LOCO+index}
                            key={index}
                        >
                            <Button>
                                loco {index}
                            </Button>
                        </AppLink>
                    )
                })}
            </div>
        </div>
    );
});
