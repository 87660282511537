import { Reducer, createContext, useContext } from "react";
import { Loco, LocoDispatch } from "../types/loco";

export const LocoContext = createContext<{
    state: Loco,
    dispatch: (value: LocoAction) => void
}>({
    state: {
        status: 'offline',
    },
    dispatch: (value: LocoAction) => {}
});

type IActionType = 'setParam' | 'setLocoId';

export interface LocoAction {
    type: IActionType,
    param?: keyof Loco,
    payload?: any,
};

export const locoReducer: Reducer<Loco, LocoAction> = (state, action) => {
    switch (action.type) {
        case 'setParam': {
            if (action.param === 'status') {
                return {
                    ...state,
                    [action.param!]: action.payload,
                };
            };

            if (action.param === 'ampere') {
                return {
                    ...state,
                    [action.param!]: Number(action.payload)/1000,
                };
            };

            if (action.param === 'volte') {
                return {
                    ...state,
                    [action.param!]: Number(action.payload),
                };
            };

            if (action.param === 'speed') {
                return {
                    ...state,
                    [action.param!]: Number(action.payload),
                };
            };

            if (action.param === 'positionPresent') {
                return {
                    ...state,
                    [action.param!]: Number(action.payload),
                };
            };

            if (action.param === 'reversPresent') {
                return {
                    ...state,
                    [action.param!]: action.payload,
                };
            };

            if (action.param === 'positionControl') {
                return {
                    ...state,
                    [action.param!]: Number(action.payload),
                };
            };

            if (action.param === 'reversControl') {
                return {
                    ...state,
                    [action.param!]: action.payload,
                };
            };

            if (action.param === 'version') {
                return {
                    ...state,
                    [action.param!]: action.payload,
                };
            };

            return state;
        }
        case 'setLocoId': {
            return {
                ...state,
                id: String(action.payload),
            }
        }
        default: {
            throw Error('Хз че за актион');
        }
    }
}

export const useLocoContext = () => useContext<{state: Loco, dispatch: LocoDispatch}>(LocoContext);
