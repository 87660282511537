import { FC, InputHTMLAttributes, memo } from 'react';

import './Input.css';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string,
}

/** Поле ввода */
export const Input: FC<InputProps> = memo((props) => {
    const {
        className,
        ...otherProps
    } = props;

    return (
        <input
            className={`Input ${className}`}
            {...otherProps}
        />
    );
});
