import { MqttClient } from "mqtt";
import { Dispatch } from 'react';

import { ArrowsByNameAction } from './context';

export const subscribeArrowByName = (
    client: MqttClient,
    stationName: string,
    dispatch: Dispatch<ArrowsByNameAction>,
) => {
    const arrowTopic = `station/${stationName}/arrows`;
    client.subscribe(`${arrowTopic}/#`, {qos: 1});
    client.on('message', (topic, message) => {
        
        if (topic.includes(arrowTopic)) {
            const arrowId = topic.split('/')[3];
            const param = topic.split('/')[4];

            switch (param) {
                case 'status': {
                    dispatch({
                        type: 'setStatus',
                        payload: message.toString(),
                        arrowIndx: arrowId,
                    });
                    break;
                }
                case 'control': {
                    dispatch({
                        type: 'setDirection',
                        payload: message.toString(),
                        arrowIndx: arrowId,
                        extraData: 'control',
                    });
                    break;
                }
                case 'present': {
                    dispatch({
                        type: 'setDirection',
                        payload: message.toString(),
                        arrowIndx: arrowId,
                        extraData: 'present',
                    });
                    break;
                }
                default: {
                    throw Error('Хз че за топик ' + topic);
                }
            }
        }
    });
};
