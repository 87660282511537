import { IClientOptions } from "mqtt/*";


export const MqqtOptions: IClientOptions = {
    protocol:'ws',
    // host: 'cifra-irk.ru',
    // hostname: 'cifra-irk.ru',
    host: '81.90.180.165',
    hostname: '81.90.180.165',
    path: '/ws',
    port: 8883,
    username: 'AndHome',
    password: 'mqtt501',
    rejectUnauthorized: false,
}
