import { MqttClient } from "mqtt";

import { Loco } from '../types/loco';
import { LocoDispatch } from '../types/loco';

export const subscribeLocoById = (
    client: MqttClient,
    locoId: string,
    dispatch: LocoDispatch,
) => {
    const loco_topic = `loc_${locoId}`;
    client.subscribe(`${loco_topic}/#`, {qos: 1});
    client.on('message', (topic, message) => {
        
        if (topic.includes(loco_topic)) {
            let locoParam: keyof Loco | undefined = undefined;
            switch (topic) {
                case `${loco_topic}/status`: {
                    locoParam = 'status';
                    break;
                }
                case `${loco_topic}/ver`: {
                    locoParam = 'version';
                    break;
                }
                case `${loco_topic}/position/control`: {
                    locoParam = 'positionControl';
                    break;
                }
                case `${loco_topic}/position/present`: {
                    locoParam = 'positionPresent';
                    break;
                }
                case `${loco_topic}/revers/present`: {
                    locoParam = 'reversPresent';
                    break;
                }
                case `${loco_topic}/revers/control`: {
                    locoParam = 'reversControl';
                    break;
                }
                case `${loco_topic}/sensor/speed`: {
                    locoParam = 'speed';
                    break;
                }
                case `${loco_topic}/sensor/A`: {
                    locoParam = 'ampere';
                    break;
                }
                case `${loco_topic}/sensor/V`: {
                    locoParam = 'volte';
                    break;
                }
                default: {
                    throw Error('Хз че за топик');
                }
            }
            dispatch({type: 'setParam', param: locoParam, payload: message.toString()});
        }
    });
};
