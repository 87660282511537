const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#%^&*()№%?';

export const generatePassword = (lenght: number = 3) => {
    let password = '';
    
    for (let i=0; i<lenght; i++) {
        const indx = Math.round(Math.random() * (characters.length-1));
        password += characters[indx];
    }

    return password;
};