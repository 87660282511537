import { Reducer, createContext, Dispatch, useContext } from "react";

import { Arrow } from "entities/Arrow";

export const ArrowsByNameContext = createContext<{
    state: Record<string, Arrow>,
    dispatch: (value: ArrowsByNameAction) => void
}>({
    state: {},
    dispatch: (value: ArrowsByNameAction) => {}
});

type IActionType = 'setDirection' | 'setStatus';

export interface ArrowsByNameAction {
    type: IActionType,
    arrowIndx: string,
    payload?: any,
    extraData?: any,
};

export const arrowsByNameReducer: Reducer<Record<string, Arrow>, ArrowsByNameAction> = (state, action) => {
    switch (action.type) {
        case 'setDirection': {
            const oldArrow: Arrow = state[action.arrowIndx];
            const type_dir = action.extraData;
            let newArrow: Arrow | undefined;

            if (oldArrow === undefined) {
                newArrow = {
                    id: action.arrowIndx,
                    [type_dir]: action.payload,
                }
            } else {
                newArrow = {
                    ...oldArrow,
                    [type_dir]: action.payload,
                }
            }
            
            state[action.arrowIndx] = newArrow;
            return {
                ...state,
                [action.arrowIndx]: newArrow
            };
        }
        
        case 'setStatus': {
            const oldArrow: Arrow = state[action.arrowIndx]
            let newArrow: Arrow | undefined;
            
            if (oldArrow === undefined) {
                newArrow = {
                    id: action.arrowIndx,
                    status: action.payload,
                }
            } else {
                newArrow = {
                    ...oldArrow,
                    status: action.payload,
                }
            }
            
            return {
                ...state,
                [action.arrowIndx]: newArrow
            };
        }
        default: {
            throw Error('Хз че за актион');
        }
    }
}

export const useArrowsByNameContext = () => useContext<{state: Record<string, Arrow>, dispatch: Dispatch<ArrowsByNameAction>}>(ArrowsByNameContext);
