import { FC, memo, useState } from 'react';

import './LocoAdminPage.css';
import { AuthLogin } from 'features/AuthLogin';
import { LocoAdminPanel } from 'features/LocoAdminPanel';
import { PasswordTopic } from 'shared/const/topic';
import { GenLocoPassword } from 'features/GenLocoPassword';


/** Админ панель для локомотивов */
export const LocoAdminPage: FC = memo(() => {
    const [isAuth, setIsAuth] = useState(false);

    if (!isAuth) {
        return (
            <>
                AdminO4ka
                <AuthLogin
                    authTopic={PasswordTopic.ADMIN}
                    onAuth={() => setIsAuth(true)}
                    className='LocoAdminPageLogin'
                />
            </>
        );
    };

    return (
        <div className='LocoAdminPagePanel'>
            <LocoAdminPanel/>
            <GenLocoPassword/>
        </div>
    );
});
