import { MqttClient } from "mqtt";

import { LocoDispatch } from "../../../../entities/Loco";

export const changePosition = (
    client: MqttClient,
    locoId: string,
    dispatch: LocoDispatch,
    value: number,
) => {
    client.publish(`loc_${locoId}/position/control`, String(value));
    dispatch({type: 'setParam', param: 'positionControl', payload: value});
}