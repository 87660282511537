import { FC, memo, useCallback, useEffect, useMemo, } from 'react';

import './ArrowsByName.css';
import { ArrowsByNameProvider } from '../ArrowsByNameProvider/ArrowsByNameProvider';
import { useGlobalContext } from 'shared/contex/contex';
import { subscribeArrowByName } from '../../model/services/subscribeArrow';
import { useArrowsByNameContext } from 'features/ArrowsByName/model/services/context';
import { ArrowCard } from 'entities/Arrow';

interface ArrowsByNameProps {
    className?: string,
    stationName: string,
}

/** Карточка для стрелок */
const ArrowsByNameCard: FC<ArrowsByNameProps> = memo((props) => {
    const {
        className,
        stationName,
    } = props;

    const {state: {client, statusMqtt}} = useGlobalContext();
    const {state: arrowsState, dispatch} = useArrowsByNameContext();

    useEffect(() => {
        if (statusMqtt !== 'ready' || client === undefined) {
            return;
        }
        subscribeArrowByName(client, stationName, dispatch)
    }, [client, dispatch, statusMqtt, stationName]);

    const onChangeDirection = useCallback((arrowId: string) => (value: string) => {
        
        if (statusMqtt !== 'ready' || client === undefined) {
            return;
        }
        client?.publish(`station/${stationName}/arrows/${arrowId}/control`, value, {retain: true});
        // dispatch({type: 'setDirection', payload: value, arrowIndx: arrowId});
        
    }, [client, statusMqtt, stationName]);

    const arrows = useMemo(() => {
        const arrowsFC = [];
        for (let key in arrowsState) {
            const arrow = arrowsState[key];

            if (arrow.id === undefined) {
                continue;
            }

            arrowsFC.push(
                <ArrowCard
                    onChangeDirection={onChangeDirection(arrow.id)}
                    key={arrow.id}
                    data={arrow}
                />
            )
        }
        return arrowsFC;
    }, [arrowsState, onChangeDirection]);

    if (statusMqtt !== 'ready' || client === undefined || arrows.length === 0) {
        return (
            <div className={`ArrowsByNameCard ${className}`}>
               Загрузонька
            </div>
        );
    }

    return (
        <div className={`ArrowsByNameCard ${className}`}>
           {arrows}
        </div>
    );
});

export const ArrowsByNameProvided: FC<ArrowsByNameProps> = memo((props) => {
    return (
        <ArrowsByNameProvider>
            <ArrowsByNameCard {...props}/>
        </ArrowsByNameProvider>
    );
});
