import { FC, memo, useState } from 'react';
import { useParams } from 'react-router';

import './LocoPage.css';
import { LocoCardById } from 'features/LocoCardById';
import { AuthLogin } from 'features/AuthLogin';
import { PasswordTopic } from 'shared/const/topic';
import LocoImg from 'shared/assets/loco.jpg';

/** Страница отрисовки данные для локомотива */
export const LocoPage: FC = memo(() => {

    const { locoId } = useParams();
    const [isAuth, setIsAuth] = useState(false);

    if (!isAuth) {
        return (
            <>
                Locomotive
                <AuthLogin
                    authTopic={PasswordTopic.LOCO}
                    onAuth={() => setIsAuth(true)}
                    className='LocoPageLogin'
                />
            </>
        );
    };
    
    return (
        <div className='LocoPage'>
            <img src={LocoImg} />
            { locoId
                ? <LocoCardById
                    locoId={locoId}
                    className='LocoPageCard'
                />
                : <>Error 404</>
            }
        </div>
    );
});
