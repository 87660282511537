import { FC, memo } from 'react';

import { Button } from 'shared/ui/Button';
import { Switcher } from 'shared/ui/Switcher';
import { Status } from 'shared/ui/Status';
import './LocoCard.css';
import { Loco } from '../../model/types/loco';

interface LocoCardProps {
    className?: string,
    loco: Loco,
    onChangePosition: (val: number) => void,
    onChangeRevers: (val: string) => void,
}

/** Карточка отображения локомотива */
export const LocoCard: FC<LocoCardProps> = memo((props) => {
    const {
        className,
        loco,
        onChangePosition,
        onChangeRevers,
    } = props;

    return (
        <div className={`${className? className: ''} LocoCard`}>
            <div>Локомотив №{loco.id}</div>
            <div className='LocoCardStatus'>
                <div>{`Статус: ${loco.status}`}</div>
                <Status active={loco.status === 'online'}/>
            </div>
            <div className='LocoCardReverse'>
                <div>Направление</div>
                <Switcher
                    checked={loco.reversControl === 'bock'? false: true}
                    setCheck={() => onChangeRevers(loco.reversControl === 'bock'? 'forth': 'bock')}
                />
            </div>
            <div className='LocoCardReverseName'>
                {loco.reversPresent === 'bock'? 'назад': 'вперед'}
            </div>
            <div className='LocoCardPosition'>
                Позиция контроллера:
                <div className='LocoCardPositionValue'>{loco.positionPresent}</div>
            </div>
            
            <div className='LocoCardPanel'>
                {Array(8).fill(0).map((val, indx) => (
                    <Button
                        theme={loco.positionControl === indx+1? 'Clicked': 'Normal'}
                        onClick={() => {onChangePosition(indx+1)}}
                        key={indx+1}
                        className='LocoCardPanelBtn'
                    >
                        {indx+1}
                    </Button>
                ))}
            </div>
            <Button
                theme={loco.positionControl === 0? 'Clicked': 'Normal'}
                onClick={() => {onChangePosition(0)}}
                className='LocoCardStopBtn'
            >
                Стоп
            </Button>
            <div className='LocoCardVolte'>
                <div className='ocoCardVolteLabel'>Напряжение:</div> 
                <div className='LocoCardVolteValue'>{`${loco.volte} V`}</div>
            </div>
            <div className='LocoCardVersion'>{`Версия: ${loco.version}`}</div>
        </div>
    );
});
