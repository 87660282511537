import { FC } from 'react';
import './Switcher.css';

interface SwitcherProps {
    checked?: boolean,
    setCheck?: () => void, 
}

export const Switcher: FC<SwitcherProps> = (props) => {
    const {
        checked,
        setCheck
    } = props;

    return (
        <button
            className='Switcher'
            onClick={setCheck}
        >
            <input
                checked={checked}
                disabled
                className="react-switch-checkbox"
                id={`react-switch-new`}
                type="checkbox"
            />
            <label
                className="react-switch-label"
                htmlFor={`react-switch-new`}
            >
                <span className={`react-switch-button`} />
            </label>
        </button>
    );
};
