import { FC, memo, useEffect, useMemo } from 'react';

import './LocoAdminCard.css';
import { Button } from 'shared/ui/Button';
import { AppRoutes } from 'shared/route/route';
import { AppLink } from 'shared/ui/AppLink/ui/AppLink';
import { Status } from 'shared/ui/Status';
import { useGlobalContext } from 'shared/contex/contex';
import { useLocoBtnContext } from '../../model/services/context';
import { subscribeLocoById } from '../../model/services/subscribeLoco';

interface LocoAdminCardProps {
    className?: string,
    locoIndx: number,
}

/** Кнопочка для перехода локомотива */
export const LocoAdminCard: FC<LocoAdminCardProps> = memo((props) => {
    const {
        className,
        locoIndx
    } = props;

    const { state: {client, statusMqtt} } = useGlobalContext();
    const { state, dispatch: locoDispatch} = useLocoBtnContext();

    useEffect(() => {
        if (!client) {
            return;
        }

        if (statusMqtt === 'ready') {
            subscribeLocoById(client, locoIndx, locoDispatch)
        }
    }, [statusMqtt, locoDispatch]);

    const index = useMemo(() => (
        locoIndx+1 < 10? `0${locoIndx+1}`: locoIndx+1
    ), [locoIndx])

    const loco = useMemo(() => (
        state[locoIndx]
    ), [state, locoIndx])

    return (
        <AppLink
            className={className}
            route={AppRoutes.LOCO+index}
        >
            <Button className={`LocoBtn ${loco.status === 'online'? 'LocoBtnNormal': 'LocoBtnLight'}`}>
                <div className='LocoBtnStatus'>
                    loco {index}
                    <Status active={loco.status === 'online'}/>
                </div>
                <div>U: {loco.volte}</div>
                <div>Pos: {loco.positionPresent}</div>
                <div>ver: {loco.version}</div>
            </Button>
        </AppLink>
    );
});
