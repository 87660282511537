import { MqttClient } from "mqtt";

import { LocoDispatch } from "../../../../entities/Loco";

export const changeDirection = (
    client: MqttClient,
    locoId: string,
    dispatch: LocoDispatch,
    value: string,
) => {
    client.publish(`loc_${locoId}/revers/control`, value);
    dispatch({type: 'setParam', param: 'reversControl', payload: value});
}
