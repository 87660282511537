import { FC, ReactNode, memo, useReducer } from 'react';

import { Loco } from '../../model/types/loco';
import { LocoContext, locoReducer } from '../../model/services/context';


interface LocoProviderProps {
    children: ReactNode,
};

const initialState: Loco = {
    id: '01',
    speed: 0,
    reversPresent: 'forth',
    positionPresent: 0,
    volte: 0,
    ampere: 0,
    version: '1.01',
    status: 'offline',
};

/** Провайдер локомонитвного контекста */
export const LocoProvider: FC<LocoProviderProps> = memo((props) => {
    const {
        children,
    } = props;

    const [state, dispatch] = useReducer(locoReducer, initialState);

    return (
        <LocoContext.Provider value={{state, dispatch}}>
            {children}
        </LocoContext.Provider>
    );
});
