import { LocoProvider } from 'entities/Loco';
import { FC, memo } from 'react';
import { LocoCardById, LocoCardByIdProps } from './LocoCardById';

/** Карточка локомотива уже с контекстом */
export const LocoCardByIdProvided: FC<LocoCardByIdProps> = memo((props) => {

    return (
        <LocoProvider>
            <LocoCardById {...props} />
        </LocoProvider>
    );
});
