import { MqttClient } from "mqtt";

export const subscribeStation = (
    client: MqttClient,
    stationName: string,
    setStatus: (status: 'online' | 'offline') => void,
) => {
    const arrowTopic = `station/${stationName}/status`;
    client.subscribe(arrowTopic, {qos: 1});
    client.on('message', (topic, message) => {
        
        if (topic.includes(arrowTopic)) {
            setStatus(message.toString() as 'online' | 'offline');
        }
    });
};
