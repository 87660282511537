import { FC, memo, useState, } from 'react';

import './StationPage.css';
import { useParams } from 'react-router';
import { StationByName } from 'features/StationByName';
import { AuthLogin } from 'features/AuthLogin';
import { PasswordTopic } from 'shared/const/topic';
import StationImg from 'shared/assets/station.png';

/** Страница станции */
export const StationPage: FC = memo(() => {

    const { stationName } = useParams();
    const [isAuth, setIsAuth] = useState(false);

    if (!stationName) {
        return <>not found</>
    } 
    
    if (!isAuth) {
        return (
            <>
                Station
                <AuthLogin
                    authTopic={PasswordTopic.LOCO}
                    onAuth={() => setIsAuth(true)}
                    className='LocoPageLogin'
                />
            </>
        );
    };

    return (
        <div
            className='StationPage'
        >
            <img src={StationImg} alt='asd'/>
            <StationByName
                stationName={stationName}
            />
        </div>
    );
});
