import { FC, ReactNode, memo } from 'react';

import './AppLink.css';
import { Link } from 'react-router-dom';

interface AppLinkProps {
    className?: string,
    route: string,
    children: ReactNode,
}

/** Ссылочка */
export const AppLink: FC<AppLinkProps> = memo((props: AppLinkProps) => {
    const {
        className,
        route,
        children,
    } = props;

    return (
        <Link 
            className={`${className? className: ''} AppLink`}
            to={route}
        >
            {children}
        </Link>
    );
});
