import { FC, memo, useCallback, useEffect } from 'react';

import { LocoCard, subscribeLocoById, useLocoContext } from '../../../entities/Loco';
import { useGlobalContext } from '../../../shared/contex/contex';
import { changePosition } from '../model/service/changePosition';
import { changeDirection } from '../model/service/changeDirection';

export interface LocoCardByIdProps {
    className?: string,
    locoId: string,
}

/** Получение картички локомотива по id */
export const LocoCardById: FC<LocoCardByIdProps> = memo((props: LocoCardByIdProps) => {
    const {
        className,
        locoId,
    } = props;

    const { state: {client, statusMqtt} } = useGlobalContext();
    const { state, dispatch: locoDispatch} = useLocoContext();

    useEffect(() => {
        if (!client) {
            return;
        }

        if (statusMqtt === 'ready') {
            subscribeLocoById(client, locoId, locoDispatch)
            locoDispatch({type: 'setLocoId', payload: locoId})
        }
    }, [statusMqtt, locoId, client])

    const onChangePosition = useCallback((val: number) => {
        if (!client || !locoId) {
            return;
        }
        changePosition(client, locoId, locoDispatch, val);
    }, [client, locoId, locoDispatch]);

    const onChangeRevers = useCallback((val: string) => {
        if (!client || !locoId) {
            return;
        }
        changeDirection(client, locoId, locoDispatch, val);
    }, [client, locoId, locoDispatch])

    return (
        <LocoCard
            className={className}
            loco={state}
            onChangePosition={onChangePosition}
            onChangeRevers={onChangeRevers}
        />
    );
});
