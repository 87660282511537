import { MqttClient } from "mqtt";
import { Dispatch } from 'react';

import { Loco } from 'entities/Loco';
import { LocoBtnAction } from './context';

export const subscribeLocoById = (
    client: MqttClient,
    locoIndx: number,
    dispatch: Dispatch<LocoBtnAction>,
) => {
    const locoId = locoIndx+1 < 10? `0${locoIndx+1}`: locoIndx+1;
    const loco_topic = `loc_${locoId}`;
    client.subscribe(`${loco_topic}/#`, {qos: 1});
    client.on('message', (topic, message) => {
        
        if (topic.includes(loco_topic)) {
            let locoParam: keyof Loco | undefined = undefined;
            switch (topic) {
                case `${loco_topic}/status`: {
                    locoParam = 'status';
                    break;
                }
                case `${loco_topic}/ver`: {
                    locoParam = 'version';
                    break;
                }
                case `${loco_topic}/position/present`: {
                    locoParam = 'positionPresent';
                    break;
                }
                case `${loco_topic}/sensor/V`: {
                    locoParam = 'volte';
                    break;
                }
                default: {
                    break;
                }
            }
            dispatch({
                type: 'setParam',
                param: locoParam,
                payload: message.toString(),
                locoIndx: locoIndx,
            });
        }
    });
};
