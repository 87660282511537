import { FC, memo, useEffect } from 'react';

import './LocoAdminPanel.css';
import { LocoAdminProvider } from '../LocoAdminProvider/LocoAdminProvider';
import { LocoAdminCard } from '../LocoAdminCard/LocoAdminCard';
import { useGlobalContext } from 'shared/contex/contex';

export interface LocoAdminPanelProps {
    className?: string,
    countLoco?: number,
}

/** Кнопочка для перехода локомотива */
export const LocoAdminPanel: FC<LocoAdminPanelProps> = memo((props) => {
    const {
        className,
        countLoco = 20,
    } = props;

    const { state: {client, statusMqtt} } = useGlobalContext();
    
    useEffect(() => {
        if (!client) {
            return;
        };

        if (statusMqtt === 'ready') {
            client.setMaxListeners(countLoco);
        };
    }, [statusMqtt, client, countLoco]);

    return (
        <LocoAdminProvider countLocoIds={countLoco}>
            <div className={`LocoPanel ${className}`}>
                {Array(countLoco).fill(0).map((_, indx) => (
                    <LocoAdminCard locoIndx={indx} key={indx} />
                ))}
            </div>
        </LocoAdminProvider>
    );
});
