import { FC, memo, useCallback, useEffect, useState } from 'react';

import './GenLocoPassword.css';
import { useGlobalContext } from 'shared/contex/contex';
import { PasswordTopic } from 'shared/const/topic';
import { Button } from 'shared/ui/Button';
import { generatePassword } from 'shared/lib/generatePassword';

interface GenLocoPasswordProps {
    className?: string,
}

/** Генерирует новый пароль для Loco */
export const GenLocoPassword: FC<GenLocoPasswordProps> = memo((props: GenLocoPasswordProps) => {
    const {
        className,
    } = props;

    const [locoPassword, setLocoPassword] = useState('');
    const {state: {client, statusMqtt}} = useGlobalContext();

    useEffect(() => {
        if (statusMqtt !== 'ready') {
            return;
        };

        client?.subscribe(PasswordTopic.LOCO);
        client?.on('message', (topic, message) => {
            if (topic !== PasswordTopic.LOCO) {
                return;
            }
            setLocoPassword(message.toString());
            client.unsubscribe(PasswordTopic.LOCO);
        });
    }, [client, setLocoPassword, statusMqtt]);

    const onGenerateClick = useCallback(() => {
        const password = generatePassword();
        client?.publish(PasswordTopic.LOCO, password, {retain: true});
        setLocoPassword(password);
    }, [client]);

    return (
        <div className={className}>
            <Button onClick={onGenerateClick}>
                Сгенерировать пароль
            </Button>
            <div>
                {`Сейчас: ${locoPassword}`}
            </div>
        </div>
    );
});
