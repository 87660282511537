import { FC, ReactNode, memo, useMemo, useReducer } from 'react';

import { Loco } from 'entities/Loco';
import { LocoBtnContext, locoBtnReducer } from '../../model/services/context';

interface LocoAdminProviderProps {
    children: ReactNode,
    countLocoIds: number,
};

/** Провайдер локомонитвного контекста */
export const LocoAdminProvider: FC<LocoAdminProviderProps> = memo((props) => {
    const {
        children,
        countLocoIds
    } = props;

    const initialState: Loco[] = useMemo(() => (
        Array(countLocoIds).fill(0).map((_, id) => ({id: String(id)}))
    ), [countLocoIds]);

    const [state, dispatch] = useReducer(locoBtnReducer, initialState);

    return (
        <LocoBtnContext.Provider value={{state, dispatch}}>
            {children}
        </LocoBtnContext.Provider>
    );
});
