import { ReactNode } from 'react';

import { LocoPage } from 'page/LocoPage';
import { MainPage } from 'page/MainPage';
import { LocoAdminPage } from 'page/LocoAdminPage';
import { StationPage } from 'page/StationPage';

interface Route {
    path: string,
    element: ReactNode,
}

const enum AppPages {
    MAIN = 'MAIN',
    LOCO = 'LOCO',
    LOCO_ADMIN = 'LOCO_ADMIN',
    STATION = 'STATION',

    NOT_FOUND = 'NOT_FOUND',
}

export const AppRoutes: Record<AppPages, string> = {
    [AppPages.MAIN]: '/',
    [AppPages.LOCO]: '/loco/', // + lodoId
    [AppPages.LOCO_ADMIN]: '/admin',
    [AppPages.STATION]: '/station/', // + stationName

    [AppPages.NOT_FOUND]: '/*',
};

export const routeConfig: Record<AppPages, Route> = {
    [AppPages.MAIN]: {
        path: AppRoutes.MAIN,
        element: <MainPage />,
    },
    [AppPages.LOCO]: {
        path: `${AppRoutes.LOCO}:locoId`,
        element: <LocoPage />,
    },
    [AppPages.LOCO_ADMIN]: {
        path: AppRoutes.LOCO_ADMIN,
        element: <LocoAdminPage />,
    },
    [AppPages.STATION]: {
        path: `${AppRoutes.STATION}:stationName`,
        element: <StationPage />,
    },

    [AppPages.NOT_FOUND]: {
        path: AppRoutes.NOT_FOUND,
        element: <>Нечего шариться!</>,
    },
};
