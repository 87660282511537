import { FC, memo, useCallback } from 'react';

import './ArrowCard.css';
import { Arrow } from '../model/types/arrow';
import { Switcher } from 'shared/ui/Switcher';
import { Status } from 'shared/ui/Status';

interface ArrowCardProps {
    className?: string,
    data?: Arrow,
    onChangeDirection?: (value: string) => void,
}

/** Карточка стрелки */
export const ArrowCard: FC<ArrowCardProps> = memo((props) => {
    const {
        className,
        data,
        onChangeDirection,
    } = props;

    const onChangeHandler = useCallback(() => {
        if (!onChangeDirection || !data?.id) {
            return;
        }
        onChangeDirection(data.control === 'plus'? 'minus': 'plus');
    }, [onChangeDirection, data]);

    if (!data?.id) {
        return null;
    }

    return (
        <div className={`Arrow ${className === undefined? '': className}`}>
            <p>{`Стрелка №${data.id}:`}</p>
            <Switcher
                checked={data.control === 'plus'}
                setCheck={onChangeHandler}
            />
            <p className='ArrowPresnt'>{`(${data.present === 'plus'? '+': '-'})`}</p>
        </div>
    );
});
