import { FC, memo, useCallback, useEffect, useState } from 'react';

import './AuthLogin.css';
import { AUTH_PASSWORD_KEY } from 'shared/const/storageKeys';
import { Input } from 'shared/ui/Input';
import { Button } from 'shared/ui/Button';
import { useGlobalContext } from 'shared/contex/contex';
import { Status } from 'shared/ui/Status';
import { PasswordTopic } from 'shared/const/topic';

interface AuthLoginProps {
    className?: string,
    authTopic: PasswordTopic,
    onAuth: () => void,
}

/** Панель входа */
export const AuthLogin: FC<AuthLoginProps> = memo((props) => {
    const {
        className,
        authTopic,
        onAuth,
    } = props;

    const [correctPassword, setCorrectPassword] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState('');
    const {state: {client, statusMqtt}} = useGlobalContext();

    useEffect(() => {
        setPassword(localStorage.getItem(AUTH_PASSWORD_KEY) || '');
        if (statusMqtt !== 'ready') {
            return;
        };

        client?.subscribe(authTopic);
        client?.on('message', (topic, message) => {
            if (topic !== authTopic) {
                return;
            }
            setCorrectPassword(message.toString());
            client.unsubscribe(authTopic);
        });
    }, [setPassword, statusMqtt, client, authTopic])

    const onAuthHandler = useCallback(() => {
        if (password === correctPassword) {
            localStorage.setItem(AUTH_PASSWORD_KEY, password);
            onAuth();
        }
    }, [onAuth, correctPassword, password]);

    return (
        <div className={`AuthLogin ${className}`}>
            <div className='AuthLoginStatus'>
                <div>Логин-форма</div>
                <Status active={correctPassword !== undefined}/>
            </div>
            <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                maxLength={20}
                placeholder='введите пароль'
            />
            <Button 
                onClick={onAuthHandler}
            >
                Войти
            </Button>
        </div>
    );
});
