import { FC, ReactNode, memo, useReducer } from 'react';

import { ArrowsByNameContext, arrowsByNameReducer } from '../../model/services/context';

interface ArrowsByNameProviderProps {
    children?: ReactNode,
}

/** Провайдер для стрелок */
export const ArrowsByNameProvider: FC<ArrowsByNameProviderProps> = memo((props) => {
    const {
        children,
    } = props;

    const [state, dispatch] = useReducer(arrowsByNameReducer, {});

    return (
        <ArrowsByNameContext.Provider value={{state, dispatch}}>
            {children}
        </ArrowsByNameContext.Provider>
    );
});
